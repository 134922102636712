import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetTobaccoTransactions } from './thunks';

import { GetTobaccoTransactionsReducer, ResetTobaccoTransactionsStateReducers } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoTransactions',
  initialState,
  reducers: {
    ResetTobaccoTransactionsState: ResetTobaccoTransactionsStateReducers,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccoTransactions, GetTobaccoTransactionsReducer);
  },
});

export default slice.reducer;
