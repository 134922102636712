import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import roomsApi from '../../../api/roomsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'rooms';

export const CreateRoom = createAsyncThunk(`${REDUCER_NAME}/createRoom`, async (data, thunkAPI) => {
  const res = await roomsApi.create(data);

  if (res.status === 200) return { ...res.data, factory: data.factory };

  return thunkAPI.rejectWithValue();
});

export const GetRooms = createAsyncThunk(`${REDUCER_NAME}/getRooms`, async (__, thunkAPI) => {
  const res = await roomsApi.get();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const GetInventoryRooms = createAsyncThunk(`${REDUCER_NAME}/getInventoryRooms`, async (__, thunkAPI) => {
  const res = await roomsApi.getInventoryRooms();

  if (res.status === 200) return res.data;

  return thunkAPI.rejectWithValue();
});

export const UpdateRoom = createAsyncThunk(`${REDUCER_NAME}/updateRoom`, async ({ data, id }, thunkAPI) => {
  const res = await roomsApi.update(data, id);

  if (res.status === 200) {
    return res.data;
  }

  return thunkAPI.rejectWithValue();
});
