import { Box, CircularProgress } from '@mui/material';
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import AccessDenied from './pages/AccessDenied';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import PageInProgress from './pages/PageInProgress';

import { AuthenticationActivity } from './pages/AuthenticationActivity';
import UserNotifications from './pages/UserNotifications';
import { UsersWithoutFactory } from './pages/UsersWithoutFactory';

import DashboardApp from './pages/DashboardApp';
import UnitByQR from './pages/UnitByQR';
import { useAuth } from './providers/AuthProvider';
import { ProtectedRoute } from './components/ProtectedRoute';

const Attendances = lazy(() => import('./pages/Attendances'));
const BoxPressedProcess = lazy(() => import('./pages/BoxPressedProcess/BoxPressedProcess'));
const CigarPerformance = lazy(() => import('./pages/CigarPerformance'));
const CigarPrices = lazy(() => import('./pages/CigarPrices'));
const CigarUnitBoxProduction = lazy(() =>
  import('./pages/CigarUnitBoxProduction/CigarUnitBoxProduction').then((module) => ({
    default: module.CigarUnitBoxProduction,
  }))
);
const CigarUnitProduction = lazy(() => import('./pages/CigarUnitProduction'));
const CigarUnitsPage = lazy(() => import('./pages/CigarUnits'));
const CigarUnitsPeriodEndPage = lazy(() => import('./pages/CigarUnitsPeriodEnd'));
const Cigars = lazy(() => import('./pages/Cigars'));
const ConveyorCigarUnitProductions = lazy(() => import('./pages/ConveyorCigarUnitProductions'));
const ConveyorStepRecords = lazy(() => import('./pages/ConveyorStepRecords'));
const ConveyorStepRecordsHistory = lazy(() => import('./pages/ConveyorStepRecordsHistory'));
const ConveyorTemplates = lazy(() => import('./pages/ConveyorTemplates'));
const ConveyorsFlowPage = lazy(() => import('./pages/ConveyorsFlowPage'));
const CycleCountPage = lazy(() => import('./pages/CycleCountPage'));
const DepartmentOrder = lazy(() => import('./pages/DepartmentOrder'));
const Distributors = lazy(() => import('./pages/Distributors'));
const EmployeeTeams = lazy(() => import('./pages/EmployeeTeams'));
const EmployeeUnitProductions = lazy(() => import('./pages/EmployeeUnitProductions'));
const Employees = lazy(() => import('./pages/Employees'));
const Expenses = lazy(() => import('./pages/Expenses'));
const Factories = lazy(() => import('./pages/Factories'));
const FactoryOptionsPage = lazy(() => import('./pages/FactoryOptionsPage'));
const FinishedGoodInventory = lazy(() => import('./pages/FinishedGoodInventory'));
const FinishedGoodTemplates = lazy(() => import('./pages/FinishedGoodTemplates'));
const Invoices = lazy(() => import('./pages/Invoices'));
const PackingLists = lazy(() => import('./pages/PackingLists'));
const PaymentCodeCigarMappingPage = lazy(() => import('./pages/PaymentCodeCigarMappingPage'));
const Payrolls = lazy(() => import('./pages/payrolls'));
const PayrollsAdd = lazy(() => import('./pages/payrolls/add'));
const PayrollsEdit = lazy(() => import('./pages/payrolls/edit'));
const PurchaseOrder = lazy(() => import('./pages/PurchaseOrder'));
const QrCodes = lazy(() => import('./pages/QrCodes'));
const RawMaterialInventory = lazy(() => import('./pages/RawMaterialInventory/RawMaterialInventory'));
const Rooms = lazy(() => import('./pages/Rooms'));
const Suppliers = lazy(() => import('./pages/Suppliers'));
const Taxes = lazy(() => import('./pages/Taxes'));
const TobaccoCorrectionsPage = lazy(() => import('./pages/TobaccoCorrectionsPage'));
const TobaccoClassificationSummaryPage = lazy(() => import('./pages/TobaccoClassificationSummaryPage'));
const TobaccoDryRoomProductionPage = lazy(() => import('./pages/TobaccoDryRoomProductionPage'));
const TobaccoHumidityRoomProductionPage = lazy(() => import('./pages/TobaccoHumidityRoomProductionPage'));
const TobaccoTransferSummaryPage = lazy(() => import('./pages/TobaccoTransferSummaryPage'));
const TobaccoGaleraProductionPage = lazy(() => import('./pages/TobaccoGaleraProductionPage'));
const TobaccoInventory = lazy(() => import('./pages/TobaccoInventory'));
const TobaccoContainersPage = lazy(() => import('./pages/TobaccoContainersPage'));
const TobaccoMappingPage = lazy(() => import('./pages/TobaccoMappingPage/TobaccoMappingsPage'));
const TobaccoProduction = lazy(() => import('./pages/TobaccoProduction'));
const TobaccoSalesPage = lazy(() => import('./pages/TobaccoSalesPage'));
const TobaccoTransactionsPage = lazy(() => import('./pages/TobaccoTransactionsPage'));
const TobaccosPage = lazy(() => import('./pages/Tobaccos/TobaccosPage'));
const Users = lazy(() => import('./pages/Users'));
const WorkInProgressInventory = lazy(() => import('./pages/WorkInProgressInventory/WorkInProgressInventory'));
const WorkInProgressInventoryHistory = lazy(() =>
  import('./pages/WorkInProgressInventoryHistory/WorkInProgressInventoryHistory')
);
const WorkOrders = lazy(() => import('./pages/WorkOrders'));

// ----------------------------------------------------------------------

export default function RouterWrapper() {
  const { onGetMe, isAuthentificated, loading } = useAuth();

  useEffect(() => {
    onGetMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '100%' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress disableShrink />
      </Box>
    );
  }

  return <Router isAuthentificated={isAuthentificated} />;
}

function Router({ isAuthentificated }) {
  return useRoutes([
    {
      path: '/:factory/unit/:qrId',
      element: <UnitByQR />,
    },
    {
      path: '/dashboard',
      element: isAuthentificated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        {
          path: 'user-notifications',
          element: <UserNotifications />,
        },
        {
          path: 'factories',
          element: (
            <ProtectedRoute path={'factories'}>
              <Suspense fallback={<CircularProgress />}>
                <Factories />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'authentication-activity',
          element: (
            <ProtectedRoute path={'authenticationActivity'}>
              <AuthenticationActivity />
            </ProtectedRoute>
          ),
        },
        {
          path: 'users-without-factory',
          element: (
            <ProtectedRoute path={'usersWithoutFactory'}>
              <UsersWithoutFactory />
            </ProtectedRoute>
          ),
        },

        {
          path: 'users',
          element: (
            <ProtectedRoute path={'users'}>
              <Suspense fallback={<CircularProgress />}>
                <Users />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigars',
          element: (
            <ProtectedRoute path={'cigars'}>
              <Suspense fallback={<CircularProgress />}>
                <Cigars />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyors',
          element: (
            <ProtectedRoute path={'conveyors'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorTemplates />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyors-flow',
          element: (
            <ProtectedRoute path={'conveyors-flow'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorsFlowPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-classification-summary',
          element: (
            <ProtectedRoute path={'tobaccoClassificationSummary'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoClassificationSummaryPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-corrections',
          element: (
            <ProtectedRoute path={'tobaccoCorrections'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoCorrectionsPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-prices',
          element: (
            <ProtectedRoute path={'cigarPrices'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarPrices />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobaccos',
          element: (
            <ProtectedRoute path={'tobaccos'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccosPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'rooms',
          element: (
            <ProtectedRoute path={'rooms'}>
              <Suspense fallback={<CircularProgress />}>
                <Rooms />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'distributors',
          element: (
            <ProtectedRoute path={'distributors'}>
              <Suspense fallback={<CircularProgress />}>
                <Distributors />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'factory-options',
          element: (
            <ProtectedRoute path={'factoryOptions'}>
              <Suspense fallback={<CircularProgress />}>
                <FactoryOptionsPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'taxes',
          element: (
            <ProtectedRoute path={'taxes'}>
              <Suspense fallback={<CircularProgress />}>
                <Taxes />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'employees',
          element: (
            <ProtectedRoute path={'employees'}>
              <Suspense fallback={<CircularProgress />}>
                <Employees />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'employee-teams',
          element: (
            <ProtectedRoute path={'employeeTeams'}>
              <Suspense fallback={<CircularProgress />}>
                <EmployeeTeams />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'attendances',
          element: (
            <ProtectedRoute path={'attendances'}>
              <Suspense fallback={<CircularProgress />}>
                <Attendances />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-performance',
          element: (
            <ProtectedRoute path={'cigarPerformance'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarPerformance />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'employeeUnitProductions',
          element: (
            <ProtectedRoute path={'employeeUnitProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <EmployeeUnitProductions />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyor-step-records',
          element: (
            <ProtectedRoute path={'conveyorStepRecords'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorStepRecords />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'conveyor-step-records-history',
          element: (
            <ProtectedRoute path={'conveyorStepRecordsHistory'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorStepRecordsHistory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-inventory',
          element: (
            <ProtectedRoute path={'tobaccoInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-containers',
          element: (
            <ProtectedRoute path={'tobaccoContainers'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoContainersPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-production',
          element: (
            <ProtectedRoute path={'conveyorCigarUnitProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <ConveyorCigarUnitProductions />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-unit-box-productions',
          element: (
            <ProtectedRoute path={'cigarUnitBoxProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitBoxProduction />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'box-pressed-process',
          element: (
            <ProtectedRoute path={'boxPressedProcess'}>
              <Suspense fallback={<CircularProgress />}>
                <BoxPressedProcess />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'workInProgressInventory',
          element: (
            <ProtectedRoute path={'workInProgressInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <WorkInProgressInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'work-in-progress-inventory-history',
          element: (
            <ProtectedRoute path={'workInProgressInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <WorkInProgressInventoryHistory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-production',
          element: (
            <ProtectedRoute path={'tobaccoProduction'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoProduction />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-transfer-summary',
          element: (
            <ProtectedRoute path={'tobaccoTransferSummary'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoTransferSummaryPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-galera-production',
          element: (
            <ProtectedRoute path={'tobaccoGaleraProduction'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoGaleraProductionPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-humidification-production',
          element: (
            <ProtectedRoute path={'tobaccoHumidificationProduction'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoHumidityRoomProductionPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-dry-production',
          element: (
            <ProtectedRoute path={'tobaccoDryProduction'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoDryRoomProductionPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-transactions',
          element: (
            <ProtectedRoute path={'tobaccoTransactions'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoTransactionsPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-mappings',
          element: (
            <ProtectedRoute path={'tobaccoMappings'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoMappingPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-good-inventory',
          element: (
            <ProtectedRoute path={'finishedGoodInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <FinishedGoodInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'rawMaterialInventory',
          element: (
            <ProtectedRoute path={'rawMaterialInventory'}>
              <Suspense fallback={<CircularProgress />}>
                <RawMaterialInventory />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'packing-lists',
          element: (
            <ProtectedRoute path={'packingLists'}>
              <Suspense fallback={<CircularProgress />}>
                <PackingLists />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'department-order',
          element: (
            <ProtectedRoute path={'departmentOrder'}>
              <Suspense fallback={<CircularProgress />}>
                <DepartmentOrder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'work-orders',
          element: (
            <ProtectedRoute path={'workOrders'}>
              <Suspense fallback={<CircularProgress />}>
                <WorkOrders />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'purchase-order',
          element: (
            <ProtectedRoute path={'purchaseOrder'}>
              <Suspense fallback={<CircularProgress />}>
                <PurchaseOrder />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'suppliers',
          element: (
            <ProtectedRoute path={'suppliers'}>
              <Suspense fallback={<CircularProgress />}>
                <Suppliers />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'payrolls',
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute path={'payrolls'}>
                  <Suspense fallback={<CircularProgress />}>
                    <Payrolls />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: 'add',
              element: (
                <ProtectedRoute path={'payrolls'}>
                  <Suspense fallback={<CircularProgress />}>
                    <PayrollsAdd />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: 'edit/:id',
              element: (
                <ProtectedRoute path={'payrolls'}>
                  <Suspense fallback={<CircularProgress />}>
                    <PayrollsEdit />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'invoices',
          element: (
            <ProtectedRoute path={'invoices'}>
              <Suspense fallback={<CircularProgress />}>
                <Invoices />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'expenses',
          element: (
            <ProtectedRoute path={'expenses'}>
              <Suspense fallback={<CircularProgress />}>
                <Expenses />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'qr-codes',
          element: (
            <ProtectedRoute path={'qrCodes'}>
              <Suspense fallback={<CircularProgress />}>
                <QrCodes />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-units',
          element: (
            <ProtectedRoute path={'cigarUnits'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitsPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-units-period-end',
          element: (
            <ProtectedRoute path={'cigarUnitsPeriodEnd'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitsPeriodEndPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cigar-unit-productions',
          element: (
            <ProtectedRoute path={'cigarUnitProductions'}>
              <Suspense fallback={<CircularProgress />}>
                <CigarUnitProduction />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'tobacco-sales',
          element: (
            <ProtectedRoute path={'tobaccoSales'}>
              <Suspense fallback={<CircularProgress />}>
                <TobaccoSalesPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'cycle-count',
          element: (
            <ProtectedRoute path={'cycleCount'}>
              <Suspense fallback={<CircularProgress />}>
                <CycleCountPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'finished-good-templates',
          element: (
            <ProtectedRoute path={'finishedGoodTemplates'}>
              <Suspense fallback={<CircularProgress />}>
                <FinishedGoodTemplates />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: 'payment-code-cigar-mapping',
          element: (
            <ProtectedRoute path={'payment-code-cigar-mapping'}>
              <Suspense fallback={<CircularProgress />}>
                <PaymentCodeCigarMappingPage />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        // { path: 'products', element: <Products /> },
        // { path: 'blog', element: <Blog /> },
      ],
    },
    {
      path: 'login',
      element: isAuthentificated ? <Navigate to="/" /> : <Login />,
    },
    // {
    //   path: 'register',
    //   element: <Register />,
    // },
    {
      path: '/',
      element: isAuthentificated ? <LogoOnlyLayout /> : <Navigate to="/login" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: '404', element: <NotFound /> },
        { path: 'in-progress', element: <PageInProgress /> },
        { path: 'access-denied', element: <AccessDenied /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
