import { createAsyncThunk } from '@reduxjs/toolkit';
// api
import tobaccoTransactionsApi from '../../../api/tobaccoTransactionsApi';

// ---------------------------------------------------------------------

const REDUCER_NAME = 'tobaccoTransactions';

export const GetTobaccoTransactions = createAsyncThunk(
  `${REDUCER_NAME}/getTobaccoTransactions`,
  async (params, thunkAPI) => {
    // params.startDate = dayjs(params.startDate).format('YYYY-MM-DD');
    // params.endDate = dayjs(params.endDate).format('YYYY-MM-DD');

    const res = await tobaccoTransactionsApi.get(params);

    if (res.status === 200) return res.data;

    return thunkAPI.rejectWithValue();
  }
);
