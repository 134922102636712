import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import useAccess from '../hooks/useAccess';

import { UserRoleEnum } from '../services/access';

// ------------------------------------------------------------------

// Core
export const Protected = ({ path, isRoute, children, roles }) => {
  const hasAccess = useAccess(path, roles);
  // console.log('Protected: path:', path, 'roles:', roles, 'hasAccess:', hasAccess);
  if (hasAccess) return children;

  return isRoute ? <Navigate to="/access-denied" /> : null;
};

Protected.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(UserRoleEnum))),
  path: PropTypes.string,
  isRoute: PropTypes.bool,
  children: PropTypes.node,
};
